var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-breadcrumb',{attrs:{"pageTitle":_vm.$t('models.school'),"breadcrumb":[
      {
        text: _vm.$t('Schools'),
        to: 'schools'
      },
      {
        text: _vm.$t('Detail'),
        active: true,
      } ]}}),(_vm.recordData)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('info-card',{attrs:{"record-data":_vm.recordData,"special_needs":_vm.special_needs},on:{"refetch-data":function($event){return _vm.getData(_vm.recordData.id)}}})],1),(_vm.recordData.user_admin_id)?_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-row',[_c('b-col',{attrs:{"xl":"6","md":"6","sm":"3"}},[_c('statistic-card-horizontal',{attrs:{"color":"success","icon":"DollarSignIcon","statistic":"48.234 €","statistic-title":"Presupuesto total de venta"}})],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"3"}},[_c('statistic-card-horizontal',{attrs:{"color":"danger","icon":"ActivityIcon","statistic":"38.668 €","statistic-title":"Valor real por licencias activas"}})],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"3"}},[(_vm.recordData)?_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'school-teachers-list', 
            params: { id: _vm.recordData.id, name: _vm.recordData.name } }}},[_c('statistic-card-vertical',{attrs:{"icon":"UserIcon","statistic":_vm.teachers_amount,"statistic-title":"Teachers","color":"info"}})],1):_vm._e()],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"3"}},[(_vm.recordData)?_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'school-students-list', 
              params: { id: _vm.recordData.id, name: _vm.recordData.name } }}},[_c('statistic-card-vertical',{attrs:{"color":"warning","icon":"HeartIcon","statistic":_vm.students_amount,"statistic-title":"Students"}})],1):_vm._e()],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"3"}},[(_vm.recordData)?_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'school-groups-list', 
              params: { id: _vm.recordData.id, name: _vm.recordData.name } }}},[_c('statistic-card-vertical',{attrs:{"color":"danger","icon":"UsersIcon","statistic":_vm.groups_amount,"statistic-title":"Groups"}})],1):_vm._e()],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"3"}},[(_vm.recordData)?_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'school-dreamers-list', 
              params: { id: _vm.recordData.id, name: _vm.recordData.name } }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"SmileIcon","statistic":_vm.dreamers_amount,"statistic-title":_vm.$t('Dreamers')}})],1):_vm._e()],1)],1)],1):_c('b-col',[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Attention! ")]),_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("School doesn't have an admin licence. Edit it to create teachers, students, groups and dreamers.")])])])],1)],1)]:_c('b-alert',{attrs:{"variant":"danger","show":_vm.recordData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error fetching school data ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No school found with this id. Check "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'schools'}}},[_vm._v(" School List ")]),_vm._v(" for other schools. ")],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }